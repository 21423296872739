<script lang="ts">
  import type { Background } from '$lib/contentful/models/background';
  import { twMerge as merge } from 'tailwind-merge';

  type AddProse<T> = T extends TextTheme ? `prose-${T}` : never;
  type TextTheme = 'dark' | 'light' | 'white';
  type ThemeClass = AddProse<TextTheme>;
  type ListStyle = 'default' | 'checkmark';
  type BaseProps = {
    text?: string;
    fieldId?: string;
    entityId?: string;
    theme?: TextTheme;
    class?: string;
    listStyle?: ListStyle;
    background?: Background;
  };

  type ThemeProps = BaseProps & {
    theme: TextTheme;
    background?: never;
  };

  type BackgroundProps = BaseProps & {
    theme?: never;
    background: Background;
  };

  type $$Props = ThemeProps | BackgroundProps;

  export let text: string | undefined = undefined;
  export let fieldId: string | undefined = undefined;
  export let entityId: string | undefined = undefined;
  export let theme: TextTheme | undefined = undefined;
  export let listStyle: ListStyle = 'default';
  export let background: Background | undefined = undefined;

  let className = '';
  export { className as class };

  const textThemesToClasses: Record<TextTheme, ThemeClass> = {
    dark: 'prose-dark',
    light: 'prose-light',
    white: 'prose-white',
  };

  const backgroundsToClasses = (background: Background): ThemeClass => {
    switch (background) {
      case 'mist':
      case 'green-gradient':
      case 'pink-gradient':
        return 'prose-light';
      case 'purple-ultraviolet-gradient':
      case 'purple-ultraviolet-gradient-stars-grid':
      case 'customer-hype-rainbow':
      case 'cloud':
      case 'oss':
      case 'abstract':
        return 'prose-white';
      case 'none':
      case 'black':
      case 'stars':
      case 'grid':
      case 'tardigrade':
      case 'black-ultraviolet-gradient-grid':
      case 'black-blue-gradient-stars':
      case 'black-blue-gradient-stars-grid':
      case 'windows-95':
      case 'deep-blue':
      case 'moonscape':
        return 'prose-dark';
      default:
        return 'prose-dark';
    }
  };

  $: themeClass = background
    ? backgroundsToClasses(background)
    : textThemesToClasses[theme!];
</script>

<!-- eslint-disable svelte/no-at-html-tags -->
{#if text}
  <div
    data-contentful-entry-id={entityId}
    data-contentful-field-id={fieldId}
    class={merge('prose max-w-none', themeClass, listStyle, className)}
  >
    <slot>{@html text}</slot>
  </div>
{/if}

<style lang="postcss">
  :global(.prose p) {
    font-size: 20px;
    line-height: 180%;
  }

  :global(.prose a) {
    color: #34d399;
    text-decoration: none;
  }

  :global(.prose a:hover) {
    text-decoration: underline;
  }

  :global(.prose blockquote) {
    @apply py-8 border-0;
    background: #1f203f;
    quotes: none;
    font-style: normal;
  }

  :global(.prose blockquote::before) {
    content: url('/images/quote.svg');
  }

  :global(.prose blockquote p) {
    font-size: 32px;
    line-height: 160%;
  }

  :global(.prose iframe) {
    width: 100%;
    height: clamp(260px, 32vw, 520px);
  }

  :global(.prose.checkmark ul > li) {
    list-style-image: url('/images/checkmark.svg');
  }
</style>
